











































































































































import { Turma } from "@/core/models/geral";
import { EnumPerfilUsuario, PageBase } from "@/core/models/shared";
import { CashBackService, TurmaService } from "@/core/services/geral";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ListaTurma extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        sortBy: ['dataInicio'],
        sortDesc: [true],
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions', sortable: false },
        { text: 'Curso', value: 'curso.nome' },
        { text: 'Código', value: 'codigo' },
        { text: 'Data Inicio', value: 'dataInicio', type:'date' },
        { text: 'Data Fim', value: 'dataFim', type:'date' },
        { text: 'Aprovado', value: 'aprovado', type:'bool' },
        { text: 'Status', value: 'ativo', type:'bool' },
    ];

    filtro: any = "ativo eq true";

    masterEnum = EnumPerfilUsuario.Master;

    headersInstrutores: any[] = [
        { text: 'Nome',value:'usuario.complementar.nome', sortable: false },
    ];
    headersSecretarios: any[] = [
        { text: 'Nome',value:'usuario.complementar.nome', sortable: false },
    ];
    headersTreinadores: any[] = [
        { text: 'Nome',value:'usuario.complementar.nome', sortable: false },
    ];


    item = new Turma();
    service = new TurmaService();

    dialogAprovacao: boolean = false;
    itemAprovacao:Turma = new Turma();

    
    turma: number = 0;

    foto: any = null;


    @Watch("turma")
    FiltroAbertaFechada(){
        if(this.turma == 0){
            this.filtro = "ativo eq true";
        }
        else if (this.turma == 1){
            this.filtro = "ativo eq false";
        }
        else{
            this.filtro = "ativo eq false and isValidada eq true";
        }

        this.Atualizar();
    }

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, 'Curso').then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => {
            if (!err.response){
                if(!navigator.onLine){
                    this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                }
                else{
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
            }
            else{
                this.$swal("Aviso", err.response.data, "error");
            }
        })
        .finally(() => (this.loading = false));
    }


    AbrirDialogCadastro(item?: Turma){
        if(item){
            this.service.ObterPorId(item.id, "Local, Curso, Acomodacoes.Acomodacao, Instrutores.Usuario, Secretarios.Usuario, Treinadores.Usuario, Alunos.Aluno").then(
                res=>{
                    this.item = new Turma(res.data);
                    this.dialogCadastro = true;
                },
                err => {
                    if (!err.response){
                        if(!navigator.onLine){
                            this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                        }
                        else{
                            this.$swal("Aviso", "Não foi possível acessar a API", "error");
                        }
                    }
                    else{
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            )
        }
        else{
            this.item = new Turma();
            this.dialogCadastro = true;
        }
    }

    Excluir(item: Turma){
        this.$swal({    
            title: 'Atenção!',
            text: 'Tem certeza que deseja excluir o registro atual?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.service.Excluir(item.id)
                .then(
                    res => {
                        if (res.status == 200){
                            return res.data;
                        }
                    },
                    err => {
                        if (err.response.status == 403){
                            this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                        }
                        else{
                            this.$swal('Aviso', err.response.data, 'error')
                        }
                    }
                )
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value){
                this.$swal("Aviso", result.value, "success");
                this.Atualizar();
            }
        })
    }
    
    GetAprovarAutorizacao(item:Turma){
        this.service.ObterPorId(item.id,"Instrutores.Usuario,Secretarios.Usuario,Treinadores.Usuario,Solicitacao.Solicitante").then(
            res => {
                this.itemAprovacao = res.data;
                this.dialogAprovacao = true
            },
            err => {
                if(err.response.status == 403){
                    this.$swal("Aviso","Você não tem permissão para essa funcionalidade","warning")
                }
                else{
                    this.$swal('Aviso', err.response.data, 'error')
                }
            }
        )
    }
    
    AprovarAutorizacao(item:Turma){
        this.service.Aprovar(item.id).then(
            res => {
                this.$swal("Aviso", "Autorização aprovada!", "success");
                this.itemAprovacao = new Turma();
                this.dialogAprovacao = false;
                this.Atualizar();
            },
            err =>{
                if (err.response.status == 403){
                this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");  
            }
            else{
                this.$swal('Aviso', err.response.data, 'error')
            }
        }
        )
    }
}
